:root {
	--nav-height: 135px;
	--nav-bottom-margin: 14px;
	--nav-shrink-duration: 400ms;
	--nav-height-minimized: 50px;
	--mobile-logo-height: 25px;
	--hamburger-width: 35px;
}

.nav-dropdown + .nav-dropdown {
	margin-top: 0.7em;
}

.toolbar-content {
	height: 0;
}

#observe-nav-scroll-js {
	height: var(--nav-height-minimized);
}

#driehaus-logo-img, #nav-expander-js, #driehaus-logo-text, #primary-nav-js {
	transition-duration: var(--nav-shrink-duration);
}

#nav-expander-js {
	cursor: pointer;
	width: 100%;
	height: var(--nav-height-minimized);
	overflow-y: hidden;
	flex-shrink: 0;
	display: flex;
	opacity: 1;
}

#nav-expander-js > * {
	margin: auto;
	margin-right: 0;
}

.nav-content {
	@apply flex items-center justify-between;
	height: var(--nav-height);
	padding-top: 18px;
}

.nav-content__right {
	@apply flex flex-col h-full justify-between;
	width: var(--hamburger-width);
}

.nav-logo {
	height: 90px;

	/* prevents the split up logo from being pushed onto a second line at very small viewport widths */
	white-space: nowrap;
}

#driehaus-logo-img {
	transform: matrix(0.4, 0, 0, 0.4, -23, 32.1);
	transition-property: transform;
}

#driehaus-logo-text {
	transform: matrix(0.4, 0, 0, 0.4, -44, 12.75);
	transition: transform var(--nav-shrink-duration);
	transform-origin: bottom left;
}

#primary-nav-js {
	@apply z-40 bg-white w-full;
	border-bottom: 1px solid theme('colors.gray.200');
	position: fixed;
	transform: translateY(calc(var(--nav-height-minimized) - var(--nav-height)));
	transition-property: transform;
}

@media screen and (max-height: 950px) {

	#primary-nav-js.minimized {
	transform: translateY(calc(var(--nav-height-minimized) - var(--nav-height)));

		#driehaus-logo-img {
			transform: matrix(0.4, 0, 0, 0.4, -23, 32.1);
		}

		#driehaus-logo-text {
			transform: matrix(0.4, 0, 0, 0.4, -44, 12.75);
		}

		#nav-expander-js {
			height: var(--nav-height-minimized);
			opacity: 1;
		}
	}

}

@media screen and (max-width: 500px) {
	.nav-logo {
		height: auto;
	}

	#driehaus-logo-img {
		transform: matrix(0.5, 0, 0, 0.5, -30, 8);
	}

	#driehaus-logo-text {
		transform: matrix(0.6, 0, 0, 0.6, -50, -10.25);
	}
}

@media screen and (min-width: 883px) {

	#observe-nav-scroll-js {
		height: var(--nav-height);
	}

	#primary-nav-js {
		transform: translateY(0);
		border-bottom: none;
	}

	#primary-nav-js.scrolled {
		border-bottom: 1px solid theme('colors.gray.200');
	}

	.toolbar-content {
		height: auto;
	}


	#driehaus-logo-img {
		transform: matrix(1, 0, 0, 1, 0, 0);
	}

	#driehaus-logo-text {
		transform: matrix(1, 0, 0, 1, 0, 0);
	}

	#nav-expander-js {
		height: var(--nav-bottom-margin);
		opacity: 0;
	}

	.nav-content__right {
		min-width: 500px;
		width: auto;
	}

}

.nav-dropdown-header.active .nav-dropdown-tab {
	pointer-events: all;
	opacity: 1;
}

.nav-dropdown-tab {
	@apply absolute bg-gray-500 p-8;
	pointer-events: none;
	margin-left: -2rem;
	border-top: var(--nav-bottom-margin) solid white;
	padding-top: calc(16px + var(--nav-bottom-margin));
	max-width: 19rem;
	opacity: 0;
	transition: opacity 400ms;
}

.nav-dropdown-header:hover, .nav-dropdown:hover {
	@apply text-gray-300;
}


@media screen and (max-width: 883px) {
	#primary-nav-js.open {
		@apply bg-gray-500 fixed inset-0;

		 /* This is needed because our nav bar is
		 tugged up when minimized, so 100vh doesn't cover it anymore */
		height: calc(100vh + (calc(var(--nav-height-minimized) + var(--nav-height))));

		#driehaus-logo-img, #driehaus-logo-text, #nav-expander-js {
			filter: brightness(0) invert(1);
		}

		#mobile-menu {
			height: calc(100vh - var(--nav-height-minimized));
			overscroll-behavior: contain;
			overflow: auto;
		}

		.mobile-menu-scroller {
			opacity: 1;
			height: auto;
		}
	}
}

.searchbox {
	position: relative;

	input {padding-left: 4px;}

	button {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
	}
}


/* MOBILE MENU */

#mobile-menu {

	/* Layout */

	ul > li > header {
		@apply flex justify-between items-center;
	}

	.top-level ul li + li {
		margin-top: 1em;
	}

	li {
		line-height: 26px;
	}

	li.top-level {
		padding-top: 0.5em;
		padding-bottom: 0.5em;
	}


	/* The button is a text string of "+" or "-" */
	.mobile-nav-open-dropdown {
		@apply text-lg;
	}

	.searchbox--mobile {
	}

	.searchbox--mobile input {
		background-color: #404040;
		color: white;
		transition-duration: 75ms;
	}

	.searchbox--mobile input::placeholder {
		color: white;
	}

	.searchbox--mobile input:focus {
		border-bottom: 1px solid white;
		transition-duration: 75ms;
	}


	/* Open/Close functionality */

	.mobile-menu-scroller {
		opacity: 0;
		height: 0;
		overflow: hidden;
	}

	.top-level ul {
		height: 0;
		overflow: hidden;
	}

	.top-level.open {
		/*TODO: Non-palette gray	*/
		background-color: #7b7b7b;

		ul {
			height: auto;
			padding-left: 2rem;
			padding-top: 1em;
		}
	}

}



/* Upper Nav */

#nav-toolbar {
	position: relative;
	height: 100%;

	details > summary {
	  list-style: none;
	  cursor: pointer;
	}

	details > summary:hover {
		@apply text-gray-300;
	}

	details > summary::marker,
	details > summary::-webkit-details-marker {
	  display: none;
	}

	#crash-in-js {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		opacity: 0;
		display: flex;
		padding-bottom: 20px;
		pointer-events: none;
	}

	#crash-in-js.interactive {
		pointer-events: all;
	}

	#crash-in-js:after {
		@apply border-b border-b-gray-500;
		position: absolute;
		content: ' ';
		width: 100vw;
		bottom: 0;
	}

	.slide-in-content {
		flex-grow: 1;
		margin-right: auto;
		font-size: large;
	}

	.slide-in-content a {
		font-size: 15px;
		margin-left: 40px;
	}


	.slide-in-content--link-list {
		@apply space-x-10;
		display: flex;
	}

}


.nav-secondary a:hover {
	@apply text-gray-300;
}
