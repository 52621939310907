.logo-use__pairing {
    /* @apply flex flex-col md:flex-row md:space-x-20 mb-16; */
}

.logo-use__pairing + .logo-use__pairing {
    margin-top: 2.5rem;
}

.logo-use__pairing + .wysiwyg {
    margin-top: 3rem;
}

.logo-use__block {

}

.logo-use__block + .logo-use__block {
    margin-top: 2rem;
}

@screen md {
    .logo-use__pairing {
        @apply flex;
        margin-bottom: 3rem;
    }

    .logo-use__block {
        flex-grow: 1;
    }

    .logo-use__block + .logo-use__block {
        margin-top: 0;
        margin-left: 2rem;
    }
}