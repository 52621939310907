.grant-grid {
    @apply border-purple-400 border-b;
    display: grid;
    --vertical-padding: 26px;
    grid-template-columns: min-content  min-content  1fr;
    grid-template-rows: var(--vertical-padding) auto 4px auto 0 auto 10px auto 23px auto var(--vertical-padding);
    grid-template-areas:
        ".... .... ...."
        "cat  cat  cat "
        ".... .... ...."
        "cash cash cash"
        ".... .... ...."
        "name name name"
        ".... .... ...."
        "desc desc desc"
        ".... .... ...."
        "date loc  .... ";
    place-items: baseline;
}

.grant-grid:last-child {
    border-bottom: none;
}

.grant-grid__date, .grant-grid__location {
    white-space: nowrap;
    margin-right: 1rem;
}

.grant-grid__date {
    grid-area: date;
}

.grant-grid__amount {
    grid-area: cash;
}

.grant-grid__category {
    grid-area: cat;
}
.grant-grid__grantee-name {
    grid-area: name;
}
.grant-grid__desc {
    grid-area: desc;
}

.grant-grid__location {
    grid-area: loc;
}

@media screen and (min-width: 1080px) {
    .grant-grid {
        --vertical-padding: 26px;
        grid-template-columns: 164px 1fr;
        grid-template-rows: var(--vertical-padding) auto 18px auto 12px auto 14px auto var(--vertical-padding);
        grid-template-areas:
        ".... ...."
        "date cat "
        ".... ...."
        "cash name"
        ".... ...."
        ".... desc"
        ".... ...."
        ".... loc ";
    }
}

.fade-in {
    /*@apply border-purple-400 border-t;*/
    animation: fadeIn 400ms;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.grants-special-note + .grants-special-note {
    margin-top: 1em;
}