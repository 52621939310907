@tailwind utilities;

main {
    /*max-width: 1600px;*/
    /*margin: 0 auto;*/
}

.max-w-14-cols {
    max-width: 1150px;
}

.max-w-12-cols {
    max-width: 980px;
}

.max-w-10-cols {
    max-width: 818px;
}

.max-w-9-cols {
    max-width: 768px;
}

@layer utilities {
    .layout-grid {
        --flex-col: minmax(0px, 82px);
        --flex-gutter: minmax(25px, 1fr);
        --content-column-grid-spec: 8 / 17;
        --content-column-maxed-grid-spec: 8 / content;
        display: grid;
        grid-template-columns: [left-gutter-start] var(--flex-gutter) [left-gutter-end content-start] repeat(16, var(--flex-col)) [content-end right-gutter-start] var(--flex-gutter) [right-gutter-end];
    }
}


.layout-grid__flow-content {
    grid-row-start: flow-start;
    grid-column: content;
}

@media screen and (min-width: 1080px) {
    .layout-grid__flow-content {
        grid-column: var(--content-column-grid-spec);
    }
}

.news-article-card {
    grid-template-rows: 1px 50px [content-start] auto auto [content-end] 50px;

    @screen md {
        grid-template-rows: 1px 50px [content-start] auto [content-end] 50px;
    }
}

.section-grid {
    --top-padding: 35px;
    --heading-height: auto;
    grid-template-rows: [top-line] var(--top-padding) [heading-start] var(--heading-height) [heading-end flow-start] auto [flow-end];
}

.banner-grid+.section-grid {
    /* We want the bottom of the hero image to be ~90 px away from the top
    of the flow content in the section beneath it, but section grids have
    their own top padding. So we check to see if we're immediately following
     such a banner, and remove the top padding*/

    --top-padding: 0;
}

.section-grid-sidebar {
    grid-row-start: heading-start;
    grid-column: content;
    margin-bottom: 2rem;
}

/* TODO: Consolidate all our mobile queries for these layout grids*/
@media screen and (min-width: 1080px) {
    .section-grid-sidebar {
        margin: 0;
        grid-row-start: flow-start;
        grid-column: content / span 5;
    }
}

.contact-banner-grid {
    --map-top-pad: 0;
    --banner-top-pad: 0;
    grid-template-rows: [banner-start] 36px [content-start] auto [content-end] var(--map-top-pad) [image-start] 256px [banner-end] 200px [image-end];
}

.contact-banner-grid__backdrop {
    grid-row: banner / image-start;
    grid-column: left-gutter / right-gutter;
}

.contact-banner-grid__hero {
    grid-row: image / image;
    grid-column: 1 / -1;
}

.contact-banner-grid__map-caption {
    max-width: 28ch;
    margin-top: 50px;
    grid-row-start: image-end;
    grid-column: content;
}

.contact-banner-grid__social-media {
    grid-row-start: 7;
    grid-column: content;
}

@media screen and (min-width: 1080px) {
    .contact-banner-grid__hero {
        grid-column: content / span 10;
    }

    .contact-banner-grid__backdrop {
        grid-row: banner;
    }

    .contact-banner-grid__map-caption {
        max-width: none;
        margin-top: 0;
        grid-row-start: image;
        grid-column: 13 / span 3;
    }

    .contact-banner-grid__social-media {
        grid-row-start: banner-end;
        grid-column: 13;
    }

    .contact-banner-grid__map-caption>* {
        @apply -ml-5 pl-5 border-l border-l-purple-400;
    }

    .contact-banner-grid {
        --map-top-pad: 26px;
        --banner-top-pad: 36px;
    }
}

.about-banner-grid {
    grid-template-rows: [banner-start] 36px [content-start] auto [content-end] 36px [image-start] auto [banner-end] 44px [flow-start];
}

.about-banner-grid__hero-img {
    grid-row: image / flow-start;
    grid-column: content;
}

.about-banner-grid__backdrop {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    border-bottom: min(115px, 36.4vw) solid white;
}

.about-banner-grid .layout-grid__flow-content {
    margin-top: 2.5rem;
}

.about-banner-grid__heading {
    grid-row-start: content;
    grid-column: content;
}

@media screen and (min-width: 1080px) {

    .about-banner-grid {
        grid-template-rows: [banner-start] 36px [content-start] auto [content-end] 36px [image-start] 50px [banner-end] 44px [flow-start];
    }

    .about-banner-grid__heading {
        grid-column: content / span 12;
    }

    .about-banner-grid__backdrop {
        grid-area: banner-start / left-gutter-start / banner-end / right-gutter-end;
        border-bottom: none;
    }

    .about-banner-grid__hero-img {
        grid-row-start: image-start;
        grid-column-start: content;
        grid-row-end: auto;
        grid-column-end: 6;
    }

    .about-banner-grid .layout-grid__flow-content {
        margin-top: 0;
    }


}



.banner-grid__heading {
    grid-column: content;
    grid-row-start: content;
}

.banner-grid {
    grid-template-rows: [banner-start] 36px [content-start] auto [content-end] 36px [image-start] auto [image-end banner-end];
    margin-bottom: 3.75rem;
}

.banner-grid__backdrop {
    grid-row: banner / image;
    grid-column: left-gutter / right-gutter;
    border-bottom: min(157px, 36.4vw) solid white;
}

.banner-grid--no-img .banner-grid__backdrop {
    border-bottom: none;
}

.banner-grid--no-img .banner-grid__heading > .tags-large:last-child {
    /* If there's a banner with no image and no large headline text,
    we want to remove the padding beneath the .tags-large (the one guaranteed element in the banner),
    so that it has equal padding on top and bottom when it comes to mobile. */
    padding-bottom: 0;
}

.banner-grid__hero-img {
    grid-row: image;
    grid-column: content;
}

/*@media screen and (min-width: 820px) {*/
/*    .banner-grid__hero-img {*/
/*        grid-column: content;*/
/*    }*/
/*}*/

.banner-grid__hero-img-caption {
    @apply border-b-purple-400 border-b;
    /* @apply border-l-purple-400 border-l; */
    @apply mt-8 pl-6;
    grid-column: content;
    grid-row: image-end / span 1;
    z-index: 0;
    padding: 8px 0 35px 0;



    overflow: hidden;
}



@media (min-width: 430px) {
    .banner-grid__hero-img-caption {
        @apply border-l-purple-400 border-l;
        margin: 0;
        padding: 0;
        border-bottom: none;
        padding-left: 15px;
    }

    .banner-grid__hero-img-caption > div {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.caption-transition-text {
    text-align: left;
    display: inline-block;
    align-self: flex-end;
    bottom: 0;
}

.banner-grid__hero-img-caption-content {position: relative; z-index: 2;}

.banner-grid__hero-img-caption--cycling::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    opacity: 1;
    content: ' ';
    z-index: 1;
}


@media screen and (min-width: 1080px) {
    .banner-grid {
        margin-bottom: 5.625rem;
    }

    .banner-grid__backdrop {
        border-bottom: min(157px, 11.4vw) solid white;
    }

    .banner-grid__hero-img {
        grid-column: content / span 10;
    }

    .banner-grid__heading {
        grid-column: content / span 12;
        grid-row-start: content;
    }

    .banner-grid__hero-img-caption {
        @apply flex flex-col self-end;
        @apply -ml-6 mt-0;
        position: relative;
        bottom: min(210px, 17.9vw);
        grid-column: 13 / span 4;
        grid-row: image;
        z-index: 0;
    }

    .banner-grid__hero-img-caption--cycling::before {
        position: absolute;
        top: -4rem;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: theme('colors.purple.100');
        opacity: 1;
        content: ' ';
        z-index: 1;
    }
}


.staff-grid {
    grid-template-rows: [heading-start] auto [heading-end headshot-start];
    grid-auto-rows: auto;
}

.staff-grid__heading {
    grid-column: 2 / right-gutter;
    margin-bottom: 50px;
}

.staff-grid__card {
    grid-column: content;
    margin-bottom: 3rem;
}

.staff-grid-card__job-title {
    /* We want to treat the tallest job title like it's 2 lines, max, and the line height of the subheadings
    happens to be 24px.*/
    max-height: 48px;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
}

@media screen and (min-width: 700px) {
    .staff-grid {
        grid-template-columns: [left-gutter-start] var(--flex-gutter) repeat(2, var(--flex-col)) [left-gutter-end headshot-start] repeat(3, var(--flex-col)) [headshot-end spacer-start] repeat(2, var(--flex-col)) [spacer-end headshot-start] repeat(3, var(--flex-col)) repeat(2, var(--flex-col)) [spacer-end headshot-start] repeat(3, var(--flex-col)) [headshot-end] var(--flex-col) [right-gutter-start] var(--flex-gutter) [right-gutter-end];
    }

    .staff-grid__card {
        grid-column-end: span 3;
        grid-row-end: span 1;
        margin-bottom: 28%;
    }

    .staff-grid-column--1 {
        grid-column-start: 1 headshot-start;
    }

    .staff-grid-column--2 {
        grid-column-start: 2 headshot-start;
    }

    .staff-grid-column--3 {
        grid-column-start: 3 headshot-start;
    }
}

.staff-directory .section-grid-sidebar {
    grid-row-start: flow-start;
    grid-column: content / span 4;
}

.staff-directory .layout-grid__flow-content {
    grid-row-start: flow-start;
    grid-column: 7 / content;
}


@media screen and (max-width: 1024px) {
    .staff-directory .section-grid-sidebar {
        grid-column: content;
    }

    .staff-directory .layout-grid__flow-content {
        grid-row-start: 5;
        grid-column-start: content;
    }
}

.staff-directory-grid {
    display: grid;
    grid-template-columns: repeat(11, var(--flex-col));
}


.staff-directory-grid__job-title {
    grid-column: 1/4;
}

.staff-directory-grid__headshot {
    grid-column: 4 / span 2;
    justify-content: center;
    position: relative;
    display: block;
}

.staff-directory-grid__headshot img {
    position: relative;
    top:0;
    left: 0;
    display: block;
    z-index: 1;
}


.staff-directory-grid__headshot-background {
    @apply bg-purple-400;
    height: 90px;
    width: 90px;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
}


.staff-directory-grid__contact-info {
    grid-column: 6 / span 4;
}

@media screen and (max-width: 704px) {
    .staff-directory-grid__contact-info>*:first-child {
        @apply my-5;
    }

    .staff-directory-grid {
        display: block;
    }

    .staff-directory-grid__headshot {
        display: none;
    }
}


.staff-modal-bio-grid {
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    grid-template-rows: [close-modal-start] auto [close-modal-end] clamp(32px, 8vw, 66px) [flow-start] auto [flow-end];
}

.staff-modal-bio-grid #close-staff-modal-js {
    width: 2.75rem;
    justify-self: end;
    grid-row: close-modal;
    grid-column-end: content;
}

.staff-modal-bio-grid .staff-bio__headshot {
    grid-column: content;
    grid-row: flow;
}

.staff-modal-bio-grid .staff-bio__copy {
    grid-column: content;
    /* Create an auto column by setting the start of this to be the end of the flow row*/
    grid-row-start: flow-end;
    max-width: 72ch;
    margin-top: calc(var(--font-size-base) * var(--leading-readability));
}

.staff-modal-bio-grid .wysiwyg ol {
    list-style: decimal;
    padding-left: 2.1em;
}

@media screen and (min-width: 525px) {
    .staff-modal-bio-grid #close-staff-modal-js {
        grid-row: flow;
    }

    .staff-modal-bio-grid .staff-bio__headshot {
        grid-column: content / span 8;
    }
}

@media screen and (min-width: 940px) {
    .staff-modal-bio-grid {
        margin-top: 4rem;
    }

    .staff-modal-bio-grid #close-staff-modal-js {
        grid-row: close-modal;
    }

    .staff-modal-bio-grid .staff-bio__headshot {
        grid-column: content / span 4;
    }

    .staff-modal-bio-grid .staff-bio__copy {
        grid-column: var(--content-column-grid-spec);
        grid-row: flow;
        margin-top: 0;
    }
}

.recent-news-grid__card {
    @apply mb-10;
    grid-column: content;
}



@media screen and (min-width: 601px) and (max-width: 999px) {
    .recent-news-grid__card {

        grid-column: content / span 11;
    }
}

@media screen and (min-width: 1000px) {
    .recent-news-grid__card {
        @apply mb-0;

        &:nth-child(1) {
            grid-column: 3 / span 4;
        }

        &:nth-child(2) {
            grid-column: 8 / span 4;
        }

        &:nth-child(3) {
            grid-column: 13 / span 4;
        }
    }

}


.grants-made-cta-grid {

    .left {
        grid-column: content;
        margin-bottom: 2rem;
        grid-row: 1;
    }

    .right {
        grid-column: content;
        grid-row: 2;
    }

}

@media screen(md) {
    .grants-made-cta-grid {

        .left {
            grid-column: content / span 7;
        }

        .right {
            grid-column-end: 17;
            justify-self: flex-end;
            grid-row: 1;
        }
    }

}

.funding-area-grid {
    @apply hover:bg-purple-100 hover:no-underline transition duration-150;

    img {
        grid-column: content;
    }

    header {
        grid-column: content;
        margin-top: 2rem;
    }

}



@media screen(md) {
    .funding-area-grid {

        img {
            grid-column: content / span 4;
        }

        header {
            margin-top: 0;
            grid-column: var(--content-column-grid-spec);
        }
    }

}

@media screen and (min-width: 1080px) {
    #inquiry-and-application-process {

        .layout-grid__flow-content {
            grid-column: 8 / content;
        }

        #inquiry-preamble.wysiwyg {
            padding-right: 10%;
            max-width: none;
        }

        #accordions-root-js {
            max-width: none;
        }
    }

}

.grants-grid {
    .grants-grid__form-ui {
        grid-column: content;
        margin-bottom: 2rem;
        margin: 0 auto;
        width: 100%;
        max-width: 430px;
    }

    .layout-grid__flow-content {}
}

@media screen and (min-width: 640px) {
    .grants-grid__form-ui { margin-left: 0; }
}

@media screen and (min-width: 1080px) {
    .grants-grid__form-ui {
        max-width: 328px;
    }

    .sticky-grid-sidebar {
        position: sticky;
        align-self: flex-start;
        top: calc(var(--nav-height) + 2rem);
    }

    .grants-grid {

        .grants-grid__form-ui {
            grid-row: 1;
            grid-column: content / span 4;
        }

        .layout-grid__flow-content {
            grid-row: 1;
            grid-column: var(--content-column-maxed-grid-spec);
            padding-top: 1rem;
            /* To get the top of this to line up with the form-ui in the lefthand column */
        }
    }
}

@media screen and (min-width: 1080px) and (max-height: 950px) {
    .sticky-grid-sidebar {
        position: sticky;
    align-self: flex-start;
    top: calc(var(--nav-height-minimized) + 2rem);
    }
}


.funding-areas-bookend {
    @apply bg-purple-400;
    flex-basis: 0;
    flex-grow: 1;
}

.funding-areas-bookend--left {
    background-position-x: right;
}

.funding-areas-bookend--right {
    background-position-x: left;
}




section.pb-20.md\:pb-32 {
    padding-bottom: 0;
}




section.pb-20.md\:pb-32 {
    padding-bottom: 0;
}


.multiple_images-inital {
    opacity: 1;
    z-index: 0;
    animation-name: fi1FadeInOut-inital;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-duration: 7s;
    animation-direction: normal;

}

.multiple_images.funding__featured-image__transition{
    opacity: 0;
    z-index: 0;
    animation-name: fi1FadeInOut;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 7s;
    animation-direction: normal;
}

/* .multiple_images.funding__featured-image__transition:nth-child(1){
    opacity: 1;
    z-index: 1;
} */

.funding__featured-image__transition-caption {
    visibility: hidden;
    margin-top: 20px;
}

.funding__featured-image__transition-caption:last-child {
    visibility: visible;
}

.multiple_images.funding__featured-image__transition-caption:last-child  {
    visibility: visible;
}


.multiple_images.funding__featured-image__transition-caption:last-child > *  {
    visibility: visible;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 10s;
    animation-name: fiCaptionFade_mobile;
    /* animation-name: fiCaptionFade_mobile; */
    animation-direction: normal;
}

@media (min-width: 430px) {
    .multiple_images.funding__featured-image__transition-caption:last-child {
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-duration: 10s;
        animation-name: fiCaptionFade;
        animation-direction: normal;
    }



    .intitial-transition {
        animation-duration: 10s;
        animation-iteration-count: 1;
        animation-name: transition-once;
        animation-direction: normal;
    }
}

@keyframes fi1FadeInOut-inital {
    0% {
        opacity: 1
    }
    77% {
        opacity: 1;
        filter: brightness(100%);
    }
    100% {
        opacity: 1;
        filter: brightness(0%);
    }
}

@keyframes fi1FadeInOut {
    0% {
        opacity: 0;
    }
    12% {
        opacity: 1;
    }
    77% {
        opacity: 1;
        filter: brightness(100%);
    }
    100% {
        opacity: 1;
        filter: brightness(0%);
    }
}

@keyframes fiCaptionFade {
    0% {
        opacity: 0;
        transform: translateX(-40px);
    }
    10% {
        opacity: 1;
        transform: translateX(0);
    }
    95% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        transform: translateX(-40px);
        opacity: 0;
    }
}


@keyframes fiCaptionFade_mobile {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    95% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes transition-once {
    0% {
        opacity: 0;
        transform: translateX(-40px);
    }
    5% {
        opacity: 1;
    }
    20% {
        transform: translateX(0);
    }
}
