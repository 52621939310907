@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  html {
    scroll-behavior: smooth;
    scroll-padding-top: 8rem;
  }

  :root {}

  :focus {
    @apply outline outline-purple-300;
  }

  body {
    @apply font-serif text-xs text-gray-500;
  }

  body:not(.user-is-tabbing) :focus {
    outline: none;
  }

  a:hover {
    @apply no-underline;
  }

  * {border-color: theme('colors.purple.300');}

}

@layer components {

  .constrained-rule {
    /*TODO: 82px, our column width at max sizes, times 16, our column count, nets out to 1312. Would rather do this
        in a more flexible way, though. */
    max-width: min(calc(100% - 50px), 1312px);
    margin: 0 auto;
  }

  .image-container {
    width: 100%;

    >div {
      position: unset !important;
    }

    .image {
      object-fit: contain;
      width: 100% !important;
      position: relative !important;
      height: unset !important;
    }
  }

  /* === GRANT RANGE SLIDER STYLES === */

  .grantSlider {
    @apply w-full;
  }

  .grantSlider-track {
    @apply h-1 top-2 bg-gray-200;

    &.grantSlider-track-0 {
      @apply bg-gray-200;
    }
  }

  .grantSlider-thumb {
    @apply cursor-pointer bg-purple-400 w-2 h-6 rounded-none outline-none;
  }

  /* === ACCORDION STYLES === */

  .accordion {
    .toggle::before,
    .toggle::after {
      content: '';
      @apply absolute w-full top-1/2 left-0 bg-gray-400;
      margin-top: -1px;
      height: 1px;
    }

    .toggle::before {
      transform: rotate(0deg);
    }

    .toggle::after {
      transform: rotate(90deg);
    }

    .toggle-btn.open .toggle {
      transform: rotate(-45deg);
    }
  }

}

.carousel__dot-group {
  .carousel__dot {
    @apply bg-purple-400 rounded-full;
    height: 10px;
    width: 10px;

    &:not(:last-child) {
      @apply mr-2;
    }
  }

  .carousel__dot--selected {
    @apply bg-purple-300;
  }
}

@layer utilities {
  .full-bleed--purple {
    box-shadow: 0 0 0 100vmax #EFEFF5;
    clip-path: inset(0 -100vmax);
  }

  .full-bleed--gray {
    box-shadow: 0 0 0 100vmax #F2F2F2;
    clip-path: inset(0 -100vmax);
  }
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

@media screen and (max-width: 600px) {
  .mobile-container {
    @apply container;
  }
}