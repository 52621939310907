.hover-image {
    @apply relative;
}

.hover-image::before {
    @apply absolute inset-0 bg-purple-400;
    content: ' ';
    width: 100%;
    height: 100%;
    z-index: -1;
    max-width: 328px; /* HACK: this lock off the bg to match the image's max width so it doesn't spill over on mobile */
}

.hover-image img {
    transition: opacity 150ms;
    opacity: 1;
}

.hover-image img:hover {
    opacity: 0.6;
}

/* Ignore hover-img:hover when inside the staff modal */
#staff-bio-modal-js .hover-image img:hover {
    opacity: 1;
}