/*Apply this to an a tag whose relative parent is a block you want to make clickable. */
.block-link:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
 }

.click-catcher {
  @apply absolute inset-0;

  .news-article-card & {
    grid-area: content;
  }

  .recent-news-grid__card & {
    z-index: 1;
  }

  .funding-area-grid & {
    grid-column: content;
    z-index: 0;
  }
}