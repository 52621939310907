.macarthur-accordions-section {
    max-width: 76ch;
    
    .accordion:first-child {
        @apply mt-10;
    }

    .accordion + .accordion-content {
        @apply mt-10;
    }

    .accordion + .accordion {
        position: relative;
        margin-top: -1px;
    }
}