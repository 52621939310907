:root {
    --font-size-7xl: 3.4375rem;          /* 55px */
    --font-size-6xl: 2.75rem;            /* 44px */
    --font-size-5xl: 2.5rem;             /* 40px */
    --font-size-4xl: 1.875rem;           /* 30px */
    --font-size-3xl: 1.625rem;           /* 26px */
    --font-size-2xl: 1.5rem;             /* 24px */
    --font-size-xl: 1.4375rem;           /* 23px */
    --font-size-lg: 1.25rem;             /* 20px */
    --font-size-base: 1.125rem;          /* 18px */
    --font-size-subheading: 1.0625rem;   /* 17px */
    --font-size-caption: 1rem;           /* 16px */
    --font-size-eyebrow: 0.9375rem;      /* 15px */
    --font-size-xs: 0.875rem;            /* 14px */

    /* TODO: Get a proper set of names for the leading */
    --leading-snug: 1.09;
    --leading-loose: 1.17;
    --leading-120: 1.2;
    --leading-125: 1.25;
    --leading-relaxed: 1.33;
    --leading-140: 1.4;
    --leading-readability: 1.444444;

    --tracking-3pct: 0.03em;
    --tracking-1px: 1px;
    --tracking-minus-half-pct: -0.005em;
    --tracking-half-pct: 0.005em;
}


.body, .wysiwyg :is(p, li) {
    font-size: var(--font-size-base);
    line-height: var(--leading-readability);
}

.wysiwyg :is(p, ol, ul) {
	margin-bottom: calc(1em * var(--leading-readability));
}

.wysiwyg {
	max-width: 76ch;
}

.wysiwyg ul {
	list-style-type: disc;
	padding-left: 2.1em;
}

.wysiwyg li ul {
    margin-top: 1rem;
}

.body-link {
}

.caption {
    @apply font-sans;
    font-size: var(--font-size-caption);
}

.h1 {
    font-size: var(--font-size-7xl);
    line-height: var(--leading-snug);
    letter-spacing: var(--tracking-1px);
}

.h2 {
    font-size: var(--font-size-6xl);
    line-height: var(--leading-snug);
    letter-spacing: var(--tracking-1px);
}

.h3 {
    font-size: var(--font-size-5xl);
    line-height: var(--leading-snug);
}

.h4 {
    font-size: var(--font-size-4xl);
    line-height: var(--leading-140);
}

.h5, .wysiwyg h5, .wysiwyg ol li {
	@apply font-sans;
	font-size: var(--font-size-2xl);
	line-height: var(--leading-125);
}

.h5-serif {
	font-size: var(--font-size-2xl);
	line-height: var(--leading-125);
}

.h6 {
    @apply font-sans;
    font-size: var(--font-size-base);
    font-weight: 700;
    line-height: var(--leading-readability);
}

.h6-italic {
}

.link-styles-large-dark-button {
}

.link-styles-small-dark-button {
}

.link-styles-small-light-button {
}

.nav-dropdown {
    color: white;
    font-size: var(--font-size-lg);
    line-height: var(--leading-120);
    letter-spacing: var(--tracking-half-pct);
}

.nav-primary {
    font-size: var(--font-size-3xl);
    letter-spacing: var(--tracking-half-pct);
}

.nav-secondary {
    @apply font-sans;
    font-size: var(--font-size-eyebrow);
    line-height: var(--leading-relaxed);
    letter-spacing: var(--tracking-minus-half-pct);
}

.small-text {
    font-size: var(--font-size-caption);
    line-height: 1;
}

.subheadings-h6 {
    font-weight: 700;
    @apply font-sans;
    line-height: var(--leading-relaxed);
}

.tags-large {
    font-weight: 700;
    @apply font-sans;
    text-transform: uppercase;
    font-size: var(--font-size-subheading);
    line-height: var(--leading-loose);
    letter-spacing: var(--tracking-3pct);
}

.tags-small-light {
    @apply font-sans;
    font-size: var(--font-size-eyebrow);
    line-height: var(--leading-loose);
}

.tags-small, .wysiwyg h4 {
    @apply font-sans text-purple-400;
    font-weight: 700;
    font-size: var(--font-size-eyebrow);
    line-height: var(--leading-loose);
    letter-spacing: var(--tracking-3pct);
}

.wysiwyg * + h4 {
	margin-top: 2.5rem;
}

.wysiwyg h4 {
	margin-bottom: 10px;
	text-transform: uppercase;
}

.wysiwyg *:last-child {
	margin-bottom: 0;
}

.wysiwyg ul > li > ul {
    list-style-type: '– ';
}

/* TODO: Figure out how to apply this in the redactor wysiwyg */
/* .wysiwyg li.hidden-bullet, .wysiwyg li:has(.hidden-bullet) { */
.wysiwyg li.hidden-bullet {
    list-style-type: none;
}


.wysiwyg a {
	font-weight: 700;
}

.wysiwyg li + li {
	margin-top: 0.8em;
}

.wysiwyg a:hover, .hover-link:hover {
    color: #7D7DAE;
    font-weight: bold;
}

.wysiwyg .grants-special-note p {
    font-size: var(--font-size-caption);
    line-height: var(--leading-snug);
}

.wysiwyg .grants-special-note {
    @apply font-sans text-gray-400;
}

@media screen and (max-width: 700px) {
    
    .h1 {
        font-size: 42px;
    }
    
    .h3 {font-size: 26px;}
    
    .h5, .wysiwyg h5, .wysiwyg ol li {
        font-size: 1.5rem;
    }
    
    .h2 {
        font-size: 2.4rem;
    }
}

@media screen and (min-width: 1080px) {
    
    .hanging-punctuation {
        margin-left: -1ex;
    }
}