dialog.staff-bio {
    opacity: 0;
    transition: opacity 400ms;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    display: flex;
    position: fixed;

    width: 100%;
    max-width: 100%;
    max-height: 100%;
    padding: 0;
    margin: 0;
    top: 0;
    pointer-events: none;
}

dialog.staff-bio[open] {
    opacity: 1;

    max-width: 100%;
    height: 100vh; 
    max-height: 100%;
    padding: 0;
    margin: 0;
    top: 0;
    pointer-events: all;

    .staff-bio__contact-email {
        display: block;
    }
}



/* TODO: Check these modals in firefox, safari, and edge. */

.staff-bio__contact-email {
    display: none;
}

dialog.staff-bio::backdrop {
    background-color: transparent;
}

.staff-grid__card {
    .staff-bio__copy {
        display: none;
    }
}

.staff-directory-grid.contact-info + .staff-directory-grid.contact-info {
    /*These cards are bordered on top and bottom, so we'll pull the sibling up 1px to prevent 2px wide borders */
    margin-top: -1px;
}