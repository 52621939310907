.news-article-card {
    @apply hover:bg-purple-100 hover:no-underline transition duration-150;
    position: relative;

    hr {
        grid-column: content;
    }

    img {
        @apply w-full mb-6;
        grid-column: content;

        @screen md {
            @apply w-auto mb-0; grid-column: content / span 4;
        }
    }

    &__category {
        grid-column: content;
        align-self: center;
        position: relative;
    }

    &__category a {
        position: relative;
    }

    &__category a:hover {
        color: theme('colors.purple.300');
    }

    

    &__title {
        grid-column: content;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

    @screen md {
        grid-column: 7 / span 10;
        }
    }

    &__link {
        margin-top: 1.5em;
    }

}



.news-entry {
    #hero-image-js {
        transform-origin: bottom left;
    }

    #gutter-image-js {
        display: block;
        opacity: 0;
        position: sticky;
        top: calc(50px + var(--nav-height));
        grid-column: content / span 4;
        grid-row: flow;
        align-self: flex-start;
    }
}

@media screen and (max-width: 1079px) {
    .news-entry {
        #hero-image-js {
            transform: none !important;
            opacity: 1 !important;
        }

        #gutter-image-js {
            display: none !important;
        }
    }
}


.recent-news-grid__card {
    position: relative;
    z-index: 0;

    h6 a {
        position: relative;
        z-index: 2;
    }

    &__img {
        z-index: 2;
        position: relative;
        transition: opacity 150ms;
        opacity: 1;
    }

    &__title {
        z-index: 2;
        position: relative;
    }
}


.recent-news__image-tint {
    background-color: #7D7DAE;
    opacity: 1;
}


